import {
  Component,
  EventEmitter,
  OnInit,
  Input,
  Output,
  AfterViewInit,
  ChangeDetectorRef,
  NgZone,
  ViewChild,
  HostListener,
  ElementRef,
} from '@angular/core';
import { NgEventBus } from 'ng-event-bus';
import {
  startOfDay,
  isBefore,
  isSameDay,
  add,
  startOfISOWeek,
  isAfter,
  isSameISOWeek,
  differenceInWeeks,
  differenceInDays,
  format,
  parseISO,
  isDate,
  getISOWeek,
  isToday,
  addDays,
  subDays,
} from 'date-fns';
import { UntypedFormGroup, UntypedFormControl, Validators, AbstractControl } from '@angular/forms';
import { RecordProjectTimeService } from '../../modules/desk/widgets/popover/record-project-time/record-project-time.service';
import { DeskSettingsService } from '../../modules/desk/services/desk-settings.service';
import { TimeRecordingService } from '../../modules/time-recording/time-recording.service';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { TimeFormatDirective } from '../../directive/time-format.directive';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { map, first, switchMap, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { ItemName } from '../../modules/time-recording/shared/tr-weekview-workingtime-table/enums';
import { StorageNotificationService } from '../../../app/core/notifications/storageNotification.service';
import { StorageKeys } from '../../core/storage/storage.keys';
import { StorageService } from '../../core/storage/storage.service';
@Component({
  selector: 'troi-record-working-time-table',
  templateUrl: './troi-record-working-time-table.component.html',
  styleUrls: ['./troi-record-working-time-table.component.scss'],
  providers: [TimeFormatDirective],
})
export class TroiRecordWorkingTimeTableComponent implements OnInit, AfterViewInit {
  @ViewChild('recordIcon') recordIcon: ElementRef; // Reference to troi-icon element
  @ViewChild('recordProjectTime') recordProjectTime: NgbPopover; // Reference to NgbPopover

  private createForm = new UntypedFormGroup({
    date: new UntypedFormControl('', [Validators.required]),
    // workTime: new UntypedFormControl('', [Validators.required]),
    startTime: new UntypedFormControl('', [Validators.required]),
    endTime: new UntypedFormControl('', [Validators.required]),
  });
  private errorMessages = {
    // workTime: 'Desktop.Widgets.Projecttimes.Error.RequiredFields.WorkTime',
    startTime: 'Timerecording.Error.RequiredFields.startTime',
    endTime: 'Timerecording.Error.RequiredFields.endTime',
  };

  public saving = false;
  public startTime: string;
  public endTime: string;
  public url: any;
  public showError = false;
  public showErrorMessage: string;

  public startTimeApi: string;
  public endTimeApi: string;

  @Input() placement = 'top bottom';
  @Input() dateFor;
  @Input() type;
  @Input() item;
  @Input() userId;
  @Input() inputValue;
  @Input() showInput;

  @Output() openStateChanged = new EventEmitter<boolean>();
  @Output() loggedWork = new EventEmitter<string>();
  @Output() closePopup = new EventEmitter<boolean>();

  constructor(
    private storageService: StorageService,
    private translate: TranslateService,
    private service: RecordProjectTimeService,
    private changeDetectorRef: ChangeDetectorRef,
    private eventBus: NgEventBus,
    public deskSettingsService: DeskSettingsService,
    public timeService: TimeRecordingService,
    private ngZone: NgZone,
    private notificationService: StorageNotificationService,
  ) {}

  ngOnInit(): void {
    this.setTime();
  }

  public ngAfterViewInit(): void {
    this.initForm();
    this.setFormValidation();
  }

  private initForm(value?): void {
    this.createForm.reset();
    const date = this.createForm.controls['date'];
    if (date instanceof UntypedFormControl) {
      date.setValue(this.dateFor);
    }

    this.setTime(value);
  }

  public isRequiredField(field: string) {
    const formField = this.createForm.get(field);
    if (!formField.validator) {
      return false;
    }
    const validator = formField.validator({} as AbstractControl);
    return validator && validator.required;
  }

  private setFormValidation(): void {}

  setTime(newInputValue?) {
    const timerecordingSettings = this.storageService.getItem(StorageKeys.TIMERECORDING_SETTINGS);

    if (timerecordingSettings) {
      if (this.type === ItemName.Grossworkingtimes) {
        this.startTime = timerecordingSettings.settings.workdayFirstHalfStart;
      }
      if (this.type === ItemName.BreaksTotal) {
        this.startTime = timerecordingSettings.settings.workdayFirstHalfEnd;
      }

      if (this.startTime) {
        this.createForm.get('startTime').setValue(this.startTime);
      }
    }

    if (this.item) {
      const eTime = newInputValue || this.inputValue;

      if (this.startTime && eTime) {
        this.endTime = this.calculateTimeDuration(this.startTime, eTime);
        this.createForm.get('endTime').setValue(this.endTime);
      }
    }
  }

  calculateTimeDuration(startTime: string, endTime: string): string {
    const start = startTime.split(':');
    const end = endTime.split(':');

    const startHour = parseInt(start[0]);
    const startMinute = parseInt(start[1]);

    const endHour = parseInt(end[0]);
    const endMinute = parseInt(end[1]);

    let durationHour = endHour + startHour;
    let durationMinute = endMinute + startMinute;

    if (durationMinute >= 60) {
      durationHour += Math.floor(durationMinute / 60);
      durationMinute = durationMinute % 60;
    }

    const duration = `${String(durationHour).padStart(2, '0')}:${String(durationMinute).padStart(2, '0')}`;

    return duration;
  }

  private setFieldAsRequired(fieldName): void {
    const field = this.createForm.get(fieldName);
    field.setValidators([Validators.required]);
    field.updateValueAndValidity();
  }

  public isErrorField(field: string) {
    const formField = this.createForm.get(field);
    return formField.dirty && !formField.valid;
  }

  public getErrorForField(field: string): string {
    const controls = this.createForm.controls;
    if (controls[field].invalid && controls[field].dirty) {
      return this.errorMessages[field];
    }
    return '';
  }

  public onEnterUp(event): void {
    if (document.activeElement) {
      (document.activeElement as HTMLInputElement)?.blur();
    }
    event.preventDefault();
  }

  public toggleOverlay(popover, value?): void {
    this.timeService.getTimerecordingSettings().subscribe(() => {
      if (this.type === ItemName.BreaksTotal) {
        this.startTimeApi = null;
        this.endTimeApi = null;

        this.getstartAndEndTime().subscribe((checkStartAndEnd) => {
          if (!checkStartAndEnd) {
            this.notificationService.showWarning(
              this.translate.instant('Timerecording.Please_log_Gross_working_time_first'),
            );
            return;
          }

          this.handlePopover(popover, value);
        });
      } else {
        this.handlePopover(popover, value);
      }
    });
  }

  private handlePopover(popover, value?): void {
    if (popover.isOpen()) {
      this.closeOverlay(popover);
    } else {
      this.openOverlay(popover, value);
    }
  }

  private getstartAndEndTime() {
    return this.timeService.getWorkingTimeLogData(this.userId, this.dateFor).pipe(
      map((response: any) => response[0]),
      map((firstItem) => {
        if (firstItem) {
          this.startTimeApi = this.timeService.convertToTime(firstItem.checkIn);
          this.endTimeApi = this.timeService.convertToTime(firstItem.checkOut);
          return true;
        } else {
          return false;
        }
      }),
      catchError((error) => {
        return of(false);
      }),
    );
  }

  openOverlay(popover, value?): void {
    if (popover.isOpen()) {
      return;
    }
    this.initForm(value);
    this.saving = false;

    popover.open();
    this.openStateChanged.emit(true);
  }

  closeOverlay(popover): void {
    if (!popover.isOpen() || this.saving) {
      return;
    }
    this.closePopup.emit(true);
    popover.close();
    this.resetAndReload();
  }

  dateChanged(pickedDate: any): void {
    if ('undefined' === typeof pickedDate.date) {
      return;
    }

    const date: number = pickedDate.date;
    const dateFromatted: string = format(new Date(date[0]), 'yyyy-MM-dd');

    this.createForm.controls['date'].setValue(dateFromatted);
  }

  onCancel(popover): void {
    this.closeOverlay(popover);
  }

  onHidden(): void {
    this.resetAndReload();
  }

  resetAndReload() {
    this.createForm.reset();
    // this.timeService.reloading.next(true);
    this.showError = false;
    this.showErrorMessage = '';
    this.openStateChanged.emit(false);
  }

  onSubmit(popover): void {
    if (!this.userId) {
      return;
    }
    this.createForm.controls['date'].setValue(this.dateFor);

    if (this.saving) {
      return;
    }
    this.createForm.get('startTime').markAsDirty();
    this.createForm.get('endTime').markAsDirty();

    if (!this.createForm.valid) {
      return;
    }

    if (this.type === ItemName.Grossworkingtimes) {
      const newJson = [];
      const checkInDateTime =
        this.createForm.value.startTime === ''
          ? null
          : `${this.createForm.value.date}T${this.createForm.value.startTime}`;
      const checkOutDateTime =
        this.createForm.value.endTime === '' ? null : `${this.createForm.value.date}T${this.createForm.value.endTime}`;

      newJson.push({
        checkIn: checkInDateTime,
        checkOut: checkOutDateTime,
        breaks: [],
      });

      this.saving = true;
      this.url = this.timeService.createWorkingTimeLogData(this.userId, this.createForm.value.date, newJson);

      if (this.url) {
        this.url.subscribe(
          (response: any) => {
            this.notificationService.showSuccess(this.translate.instant('Common.labels.dataSuccessfullySaved'));
            this.saving = false;
            this.showError = false;
            this.showErrorMessage = '';
            this.initForm();
            this.loggedWork.emit(response.success);
            this.eventBus.cast('app:project-time:logged');
            this.timeService.reloading.next(true);
            this.closeOverlay(popover);
          },
          (error: any) => {
            this.saving = false;
            this.showError = true;
            if (error.error.breaks && error.error.breaks.length > 0) {
              // this.showErrorMessage = error.error.breaks[0].breakStop[0];
              this.notificationService.showError(error.error.breaks[0].breakStop[0]);
            } else if (error.error.checkOut && error.error.checkOut.length > 0) {
              // this.showErrorMessage = error.error.checkOut[0];
              this.notificationService.showError(error.error.checkOut[0]);
            } else {
              this.notificationService.showError(this.translate.instant('Common.error.general'));
              // this.showErrorMessage = 'Something went wrong please try again.';
            }
          },
        );
      }
    }

    if (this.type === ItemName.BreaksTotal) {
      const newJson = [];
      const breakStartTime =
        this.createForm.value.startTime === ''
          ? null
          : `${this.createForm.value.date}T${this.createForm.value.startTime}`;
      const breakStopTime =
        this.createForm.value.endTime === '' ? null : `${this.createForm.value.date}T${this.createForm.value.endTime}`;

      newJson.push({
        checkIn: this.startTimeApi === null ? null : `${this.createForm.value.date}T${this.startTimeApi}`,
        checkOut: this.endTimeApi === null ? null : `${this.createForm.value.date}T${this.endTimeApi}`,
        breaks: [
          {
            breakStart: breakStartTime,
            breakStop: breakStopTime,
          },
        ],
      });

      this.saving = true;
      this.url = this.timeService.createWorkingTimeLogData(this.userId, this.createForm.value.date, newJson);

      if (this.url) {
        this.url.subscribe(
          (response: any) => {
            this.notificationService.showSuccess(this.translate.instant('Common.labels.dataSuccessfullySaved'));
            this.saving = false;
            this.showError = false;
            this.showErrorMessage = '';
            this.initForm();
            this.loggedWork.emit(response.success);
            this.eventBus.cast('app:project-time:logged');
            this.timeService.reloading.next(true);
            this.closeOverlay(popover);
          },
          (error: any) => {
            this.saving = false;
            this.showError = true;
            if (error.error.breaks && error.error.breaks.length > 0) {
              // this.showErrorMessage = error.error.breaks[0].breakStop[0];
              this.notificationService.showError(this.translate.instant(error.error.breaks[0].breakStop[0]));
            } else if (error.error.checkOut && error.error.checkOut.length > 0) {
              // this.showErrorMessage = error.error.checkOut[0];
              this.notificationService.showError(this.translate.instant(error.error.checkOut[0]));
            } else {
              // this.showErrorMessage = 'Something went wrong please try again.';
              this.notificationService.showError(this.translate.instant('Common.error.general'));
            }
          },
        );
      }
    }

    return;
  }

  hideInputField(item) {
    item.showInput = false;
  }

  getValueInputField(item) {
    if (item.inputValue) {
      const eTime = this.inputValue;

      if (this.startTime && eTime) {
        this.endTime = this.calculateTimeDuration(this.startTime, eTime);
        this.createForm.get('endTime').setValue(this.endTime);
      }

      this.openPopover();
    }
    item.showInput = false;
  }

  openPopover() {
    this.recordProjectTime.open();
  }

  onModelChange(newValue, recordProjectTime) {
    if (newValue) {
      this.toggleOverlay(recordProjectTime, newValue);
    }
  }

  @HostListener('keydown.enter', ['$event'])
  handleEnterEvent(event: KeyboardEvent) {
    if (this.recordProjectTime.isOpen()) {
      this.onSubmit(this.recordProjectTime);
    }
  }
}
