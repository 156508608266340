import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, catchError, map, of, take } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { ModuleInterceptor } from '../../../core/enums/module-interceptor';
import { Routes } from '../enum/routes';

import { StorageService } from '../../../core/storage/storage.service';
import { UserService } from '../../../core/user/user.service';
import { PersonInterface } from '../../../shared/troi-person/interfaces/person.interface';
import { DropdownRoutesEnum } from '../../common/enums/dropdown-routes.enum';
import { AssigneeInterface } from '../interfaces/assignee.interface';
import { CustomerDropdownInterface } from '../interfaces/customer.interface';
import { ForecastTeamInterface } from '../interfaces/forecast.interface';
import { ProjectInterface } from '../interfaces/project.interface';
import {
  CustomerDropdownResponseInterface,
  EmployeeResponseInterface,
  ProjectResponseInterface,
  StatusResponseInterface,
  TagDropdownResponseInterface,
  TasksResponseInterface,
  TasksTimelineResponseInterface,
  TeamResponseInterface,
} from '../interfaces/responses.interface';
import { StatusInterface } from '../interfaces/status.interface';
import { SubtaskInterface } from '../interfaces/subtask.interface';
import { TagDropdownInterface } from '../interfaces/tag.interface';
import { TaskTimelineInterface } from '../interfaces/task-timeline.interface';
import { TaskInterface } from '../interfaces/task.interface';
import { TaskModel } from '../models/task.model';
import { TasksHelperService } from '../services/helper.service';

@Injectable()
export class TasksService {
  isLoading = false;
  totalCount = 0;
  listLoaded = new Subject();
  reloadKanban = new Subject();
  statusOptionsLoaded = new BehaviorSubject(false);
  employeeOptionsLoaded = new BehaviorSubject(false);
  projectOptionsLoaded = new BehaviorSubject(false);
  public isTimelineContext = false;

  private headers: HttpHeaders;

  public tasks: TaskModel[] = [];
  public timelineTasks: TaskTimelineInterface[] = [];
  public unassignedTimelineTasks: TaskModel[] = [];
  public resourceTasks: ForecastTeamInterface[] = [];
  public statusOptions: StatusInterface[] = [];

  public employeeOptions: PersonInterface[] = [];
  public projectOptions: ProjectInterface[] = [];
  public tagOptions: TagDropdownInterface[] = [];
  public customerOptions: CustomerDropdownInterface[] = [];
  private project: ProjectInterface;

  public constructor(
    private http: HttpClient,
    private helperService: TasksHelperService,
    private userService: UserService,
    private storageService: StorageService,
  ) {
    this.headers = new HttpHeaders().set(ModuleInterceptor.TASKPLANNING, '1');
    this.getTimelineContext();
    this.listLoaded.subscribe(() => this.getStatuses());
    // take --> auto-unsubscribe
    this.getEmployeeOptions()
      .pipe(
        take(1),
        catchError((e: any) => {
          console.log(e.toString());
          this.employeeOptionsLoaded.next(true);
          return [];
        }),
      )
      .subscribe((res: EmployeeResponseInterface) => {
        this.employeeOptions = res.data.map((e: PersonInterface) => {
          e.name = `${e.firstName} ${e.lastName}`;
          return e;
        });
        this.employeeOptionsLoaded.next(true);
      });

    this.getTagOptions().subscribe((res) => {
      this.tagOptions = res.items as TagDropdownInterface[];
    });

    this.getCustomerOptions().subscribe((res) => {
      if (res) this.customerOptions = res.items as CustomerDropdownInterface[];
    });

    this.helperService.projectId.subscribe((value) => {
      if (value)
        this.project = {
          id: +value,
        };
    });
  }

  public getStatuses() {
    this.getStatusOptions()
      .pipe(
        take(1),
        catchError((e: any) => {
          console.log(e.toString());
          this.statusOptionsLoaded.next(true);
          return [];
        }),
      )
      .subscribe((res: StatusResponseInterface) => {
        const statusOptions = res.data as StatusInterface[];

        this.statusOptions = statusOptions.sort((a, b) => {
          if (a.statusGroup.id === 5) return 1;
          else if (b.statusGroup.id === 5) return -1;
          else return a.orderId - b.orderId;
        });

        this.statusOptionsLoaded.next(true);
      });
  }

  public loadTaskList(taskUrl: string) {
    this.isLoading = true;
    this.getTasks(taskUrl)
      .pipe(
        take(1),
        catchError((e: any) => {
          console.log(e.toString());
          this.isLoading = false;
          this.totalCount = 0;
          this.listLoaded.next(true);
          return [];
        }),
      )
      .subscribe((result: TasksResponseInterface | TasksTimelineResponseInterface) => {
        this.isLoading = false;
        if (Array.isArray(result.data)) {
          if (this.isTimelineContext) {
            const res = result as TasksTimelineResponseInterface;
            this.unassignedTimelineTasks = res.unassignedTasks.map((t) => new TaskModel(t));
            this.timelineTasks = res.data;
          } else {
            const data = [...result.data] as TaskModel[];
            this.tasks = data.map((t: TaskModel) => {
              return new TaskModel(t);
            });
          }
        } else {
          this.resourceTasks = result as any;
        }

        this.totalCount = result.totalCount;
        this.listLoaded.next(true);
      });
  }

  public loadKanbanTasks(taskUrl: string, statusId: number): Observable<TasksResponseInterface> {
    const apiUrl = taskUrl + `&status=${statusId}`;
    return this.getTasks(apiUrl).pipe(
      take(1),
      catchError((e: any) => {
        console.log(e.toString());
        this.isLoading = false;
        this.totalCount = 0;
        return [];
      }),
    );
  }

  private buildProjectTask(task: TaskModel | TaskTimelineInterface): TaskModel | TaskTimelineInterface {
    if (!(task as TaskModel).calculationPosition) {
      return task;
    }

    const projectTask = task as TaskModel;
    const projectpath = `${projectTask.calculationPosition.customer.name} -
    ${projectTask.calculationPosition.project.name} >
    ${projectTask.calculationPosition.project.number} >
    ${projectTask.calculationPosition.subproject.name}`;

    (task as TaskModel).projectpath = projectpath;
    return task;
  }

  public getTasks(url: string): Observable<TasksResponseInterface | TasksTimelineResponseInterface> {
    return this.http
      .get<TasksResponseInterface | TasksTimelineResponseInterface>(url, {
        headers: this.headers,
      })
      .pipe(
        map((result: TasksResponseInterface | TasksTimelineResponseInterface) => {
          if (!Array.isArray(result.data)) {
            return result;
          }

          if (this.isTimelineContext) {
            (result as TasksTimelineResponseInterface).data.forEach((e) => {
              e.projects.forEach((p) => {
                p.projectTasks.map((t) => {
                  return this.buildProjectTask(t as unknown as TaskModel);
                });
              });
            });
          } else {
            (result as TasksResponseInterface).data.map((t: TaskModel) => {
              return this.buildProjectTask(t);
            });
          }

          return result;
        }),
      );
  }

  public createTask(newTask: TaskInterface): Observable<TasksResponseInterface> {
    const task = {
      title: newTask.title,
      description: newTask.description,
      priority: newTask.priority,
      startDate: newTask.startDate ?? undefined,
      endDate: newTask.endDate ?? undefined,
      subtaskAll: newTask.subtaskAll ? newTask.subtaskAll : 0,
      subtaskFinished: newTask.subtaskFinished ? newTask.subtaskFinished : 0,
      hoursBudget: newTask.hoursBudget ? newTask.hoursBudget : 0,
      hoursSpent: newTask.hoursSpent ? newTask.hoursSpent : 0,
      averageDailyHours: newTask.averageDailyHours ? newTask.averageDailyHours : 8,
      projectpath: newTask.projectpath ? newTask.projectpath : 'Unknown',
      isDeleted: false,
      createdBy: {
        id: this.userService.getUser().id,
      },
      clientId: newTask.clientId,
      project: this.project ? this.project : newTask.project,
      subproject: newTask.calculationPosition?.subproject,
      calculationPosition: newTask.calculationPosition,
      statusGroup: newTask.statusGroup,
      status: newTask.status ?? this.statusOptions[0],
      subtasks: newTask.subtasks,
      comments: newTask.comments,
      attachments: newTask.attachments,
      assignees: newTask.assignees,

      kanbanOrder: newTask.kanbanOrder
        ? newTask.kanbanOrder
        : this.tasks.length > 0
        ? this.tasks[this.tasks.length - 1].kanbanOrder + 10000
        : 100000,
    };

    return this.http.post<TasksResponseInterface>(environment.url + Routes.TASKS, task, {
      headers: this.headers,
    });
  }

  public updateTask(task: TaskModel | SubtaskInterface): Observable<TasksResponseInterface> {
    return this.http.put<TasksResponseInterface>(
      environment.url + Routes.TASKS + `/${task.id}`,
      {
        ...task,
        modifiedBy: {
          id: this.userService.getUser().id,
        },
      },
      {
        headers: this.headers,
      },
    );
  }

  public updateAssignee(taskId: string, assignee: AssigneeInterface, isSubtask: boolean = false): Observable<any> {
    return this.http.put<any>(
      environment.url + (!isSubtask ? Routes.TASKS : Routes.SUBTASKS) + `/${taskId}/assignee/${assignee.id}`,
      assignee,
      {
        headers: this.headers,
      },
    );
  }

  public deleteTask(task: TaskModel): Observable<TasksResponseInterface> {
    return this.http.delete<TasksResponseInterface>(environment.url + Routes.TASKS + `/${task.id}`);
  }

  public findTaskById(taskId: number): Observable<TasksResponseInterface> {
    return this.http.get<TasksResponseInterface>(environment.url + Routes.FIND_TASK_BY_ID + `/${taskId}`);
  }

  public getTagOptions(): Observable<TagDropdownResponseInterface> {
    return this.http.get<TagDropdownResponseInterface>(environment.url + Routes.TAGS_DROPDOWN, {
      headers: this.headers,
    });
  }

  public getCustomerOptions(): Observable<CustomerDropdownResponseInterface> {
    const clientId = new URLSearchParams(window.parent.location.search).get('client');
    const client = clientId ? `?client=${clientId}` : null;
    if (client) {
      return this.http.get<CustomerDropdownResponseInterface>(environment.url + DropdownRoutesEnum.CUSTOMERS + client, {
        headers: this.headers,
      });
    } else return of(null);
  }

  private getStatusOptions(): Observable<StatusResponseInterface> {
    const clientId = new URLSearchParams(window.parent.location.search).get('client');
    const client = clientId ? `?mandantId=${clientId}` : '?mandantId=*';
    return this.http.get<StatusResponseInterface>(environment.url + Routes.STATUS + client, {
      headers: this.headers,
    });
  }

  private getEmployeeOptions(): Observable<EmployeeResponseInterface> {
    return this.http.get<EmployeeResponseInterface>(environment.url + Routes.EMPLOYEES, {
      headers: this.headers,
    });
  }

  public getProjectOptions(page?: number, search?: string): Observable<ProjectResponseInterface> {
    let url = environment.url + Routes.PROJECTS;

    if (page !== undefined && page !== null) {
      url += `?page=${page}&pageSize=10`;
    }

    if (search) {
      if (page) url += '&';
      else url += '?';
      url += `search=${search}`;
    }

    return this.http.get<ProjectResponseInterface>(url, {
      headers: this.headers,
    });
  }

  public getAssigneeOptions(
    projectId?: number,
    clientId?: number,
    startDate?: number,
    endDate?: number,
  ): Observable<EmployeeResponseInterface> {
    let searchQuery = '?';
    if (projectId) searchQuery += `clientId=${clientId}&`;
    if (projectId) searchQuery += `projectId=${projectId}&`;
    if (startDate) searchQuery += `timerangeFrom=${startDate}&`;
    if (endDate) searchQuery += `timerangeTo=${endDate}`;

    return this.http.get<EmployeeResponseInterface>(environment.url + Routes.ASSIGNEES + searchQuery, {
      headers: this.headers,
    });
  }

  public getTeamAssigneeOptions(
    projectId?: number,
    startDate?: number,
    endDate?: number,
  ): Observable<TeamResponseInterface> {
    let searchQuery = '?';
    if (projectId) searchQuery += `projectId=${projectId}&`;
    if (startDate) searchQuery += `timerangeFrom=${startDate}&`;
    if (endDate) searchQuery += `timerangeTo=${endDate}`;

    return this.http.get<TeamResponseInterface>(environment.url + Routes.TEAM_UNITS + searchQuery, {
      headers: this.headers,
    });
  }

  public updateSubtasksStatus(task: TaskModel) {
    const statusesToChange = {
      subtaskIds: task.subtasks.filter((subtask) => subtask.status.statusGroup.id !== 5).map((subtask) => subtask.id),
      statusId: task.status.id,
    };

    return this.http.put<TasksResponseInterface>(
      environment.url + Routes.TASKS_SUBTASKS_STATUS_BATCH,
      statusesToChange,
      {
        headers: this.headers,
      },
    );
  }

  public sendEmailNotificationToAssignees(taskId: number, newAssignees: AssigneeInterface[]) {
    const assignees = {
      projectTaskId: taskId,
      employeeIds: newAssignees.map((a) => a.user.id),
    };

    return this.http.post<TasksResponseInterface>(environment.url + Routes.TASKS_EMAIL_NOTIFICATION, assignees, {
      headers: this.headers,
    });
  }

  private getTimelineContext() {
    this.helperService.isTimelineContext.subscribe((value) => {
      this.isTimelineContext = value;
    });
  }

  public getTeams(): Observable<{
    teams: UtilTeam[];
    employees: UtilEmployee[];
  }> {
    const clientId = new URLSearchParams(window.parent.location.search).get('client');
    const client = clientId ? `?clientId=${clientId}` : '';

    const url = `${environment.url}/components/taskplanning/projecttask/projectunit${client}`;
    return this.http.get(url).pipe(
      map((response: UtilRes) => {
        const data: {
          teams: UtilTeam[];
          employees: UtilEmployee[];
        } = {
          teams: [],
          employees: [],
        };

        response.data.forEach((d) => {
          if (!d.isActive || d.isDeleted) return;

          const label = d.description;
          data.teams.push({
            value: d.id,
            label,
            queryType: 'team',
          });

          d.employees.forEach((e) => {
            data.employees.push({
              value: e.id,
              label: `${e.lastName}, ${e.firstName}`,
              queryType: 'employee',
              groups: [{ id: d.id }],
            });
          });
        });

        // sort employees by label
        data.employees.sort((a, b) => {
          if (a.label < b.label) return -1;
          if (a.label > b.label) return 1;
          return 0;
        });

        return data;
      }),
    );
  }
}

export interface UtilRes {
  data: Datum[];
  totalCount: number;
  type: string;
}

export interface Datum {
  id: number;
  description: string;
  isActive: boolean;
  isDeleted: boolean;
  employees: Employee[];
  utilization: number;
}

export interface Employee {
  id: number;
  lastName: string;
  firstName: string;
  name: string;
  utilization: number;
}

interface UtilTeam {
  value: number;
  label: string;
  queryType: string;
}

interface UtilEmployee {
  value: number;
  label: string;
  queryType: string;
  groups: { id: UtilTeam['value'] }[];
}
