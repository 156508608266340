import { Component, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { SettingsEmitter } from '../../core/emitters/settings.emitter';
import { AccessRight } from '../../core/interfaces/backend.response.interface';
import { LanguagesService } from '../../core/services/languages.service';
import { FiltersInterface } from '../../shared/troi-data-listing-filters/filters.interface';
import { FilterSetSavedInterface } from '../../shared/troi-filter-sets/interfaces/filter-set-saved.interface';
import { DeleteConfirmationComponent } from '../../shared/troi-filter-sets/modals/delete-confirmation/delete-confirmation.component';
import { SaveFilterSetComponent } from '../../shared/troi-filter-sets/modals/save-filter-set/save-filter-set.component';
import { FilterSetModel } from '../../shared/troi-filter-sets/models/filter-set.model';
import { ModalService } from '../../shared/troi-modals/modal.service';
import { ColumnsEnum } from './enum/columns.enum';
import { SettingsModel } from './models/settings.model';
import { TreeViewListNetworkService } from './modules/tree-view/network/tree-view-list.network';
import { TreeViewListService } from './modules/tree-view/services/tree-view-list.service';
import { ColumnsService } from './services/columns.service';
import { FiltersFormService } from './services/filters-form.service';
import { FiltersService } from './services/filters.service';
import { ProjectListSettingsService } from './services/project-list-settings.service';

@Component({
  selector: 'project-list',
  templateUrl: './project-list.component.html',
  styleUrls: ['./project-list.component.scss'],
})
export class ProjectListComponent implements OnInit, OnDestroy {
  private defaultClientAssignedSubscription: Subscription;
  private filterSetCreated = new EventEmitter<FilterSetSavedInterface>();

  constructor(
    public projectListSettingsService: ProjectListSettingsService,
    public treeViewListService: TreeViewListService,
    private settingsEmitter: SettingsEmitter,
    public filtersService: FiltersService,
    public filtersFormService: FiltersFormService,
    public columnsService: ColumnsService,
    public languagesService: LanguagesService,
    public modalService: ModalService,
    private treeListNetworkService: TreeViewListNetworkService,
  ) {}

  public ngOnInit(): void {
    const searchPhrase =
      window.top.location.href
        ?.split('?')[1]
        ?.split('&')
        ?.find((param) => param.includes('searchPhrase'))
        ?.split('=')[1] ?? '';

    this.treeListNetworkService.initialSearchPhrase = searchPhrase;

    if (this.projectListSettingsService.settings && this.filtersService.getSelectedClientId()) {
      this.projectListSettingsService
        .prepareSettings(this.filtersService.getSelectedClientId())
        .subscribe(this.handlePrepareSettingsResponse);
    } else {
      this.defaultClientAssignedSubscription = this.filtersService.defaultClientAssigned.subscribe(() => {
        this.emitFiltersChanged(true, false);
        this.projectListSettingsService
          .prepareSettings(this.filtersService.getSelectedClientId(), true)
          .subscribe(this.handlePrepareSettingsResponse);
      });
    }

    this.filterSetCreated.subscribe((filterSetSaved: FilterSetSavedInterface) => {
      if (filterSetSaved.isNew) {
        this.filtersService.addFilterSet(filterSetSaved.filterSet);
      } else {
        this.filtersService.updateFilterSetValue(filterSetSaved.filterSet);
      }

      this.filtersService.areFiltersEdited = false;
      this.filtersService.selectedFilterSetId = filterSetSaved.filterSet.id;
      this.filtersService.filterSetSelected.next(
        filterSetSaved.isNew ? this.filtersService.selectedFilterSet : filterSetSaved.filterSet,
      );
    });
  }

  public get client(): number {
    return this.filtersService.actualFilters?.dropdownFirst ?? null;
  }

  public clientChanged(filters: FiltersInterface): void {
    this.projectListSettingsService
      .prepareSettings(filters.dropdownFirst, true)
      .subscribe(this.handlePrepareSettingsResponse);

    this.filtersService.areFiltersEdited = true;
    this.filtersService.resetFilters();
    this.emitFiltersChanged(true);
  }

  public filterChanged(filters: FiltersInterface): void {
    const newFilterParams = this.filtersFormService.prepareSearchRequestParams(filters);
    const selectedFilterSetParams = this.filtersFormService.prepareSearchRequestParams(
      this.filtersService.selectedFilterSet.filters,
    );

    this.filtersService.areFiltersEdited = JSON.stringify(newFilterParams) !== JSON.stringify(selectedFilterSetParams);

    this.filtersService.actualFilters = filters;
    this.filtersService.resetPagination();
    this.emitFiltersChanged();
  }

  public filterReset(): void {
    this.filtersService.resetFilters();
    this.emitFiltersChanged();
  }

  private emitFiltersChanged(generateCustomers = false, emit = true): void {
    if (generateCustomers) {
      this.filtersService.generateCustomersDropdown(this.filtersService.basicFiltersService.clients);
    }

    if (emit) {
      this.filtersService.haveFiltersChanged.next(this.filtersService.actualFilters);
    }
  }

  public filterSetSelected(filterSetId: string | number): void {
    this.filtersService.areFiltersEdited = false;

    this.filtersService.selectedFilterSetId = filterSetId;
    this.filtersService.filterSetSelected.next(this.filtersService.selectedFilterSet);
  }

  public filterSetDeleted(filterSet: FilterSetModel): void {
    this.modalService.init(
      DeleteConfirmationComponent,
      {
        executeCallback: () => this.filtersService.deleteFilterSet(filterSet),
      },
      {},
      '500px',
      '500px',
      true,
    );
  }

  public filterSetAsDefault(filterSet: FilterSetModel): void {
    this.filtersService.updateFilterSet(filterSet, true);
  }

  public openFilterSetSaveModal(): void {
    this.modalService.object = {
      event: null,
      object: {
        ...this.filtersService.selectedFilterSet,
        filters: this.filtersService.actualFilters,
        value: this.filtersService.generateUrlParams(
          this.filtersFormService.prepareSearchRequestParams(this.filtersService.actualFilters),
          true,
        ),
      },
    };
    this.modalService.init(SaveFilterSetComponent, {}, { filterSetCreated: this.filterSetCreated }, '500px', '600px');
  }

  private handlePrepareSettingsResponse = (settings: SettingsModel) => this.settingsEmitter.setSettings(settings);

  public ngOnDestroy(): void {
    this.defaultClientAssignedSubscription.unsubscribe();
  }

  columnsToRemove(): string[] {
    if (
      this.projectListSettingsService._settings?.permissions?.projectReporting !== AccessRight.WRITE &&
      this.projectListSettingsService._settings?.permissions?.projectReporting !== AccessRight.READ
    ) {
      return [
        ColumnsEnum.ACTUAL_TO_DEBIT_AMOUNT,
        ColumnsEnum.ACTUAL_TO_DEBIT_QUANTITY,
        ColumnsEnum.ACTUAL_TO_DEBIT_INTERNAL_QUANTITY,
        ColumnsEnum.ACTUAL_TO_DEBIT_INTERNAL_AMOUNT,
        ColumnsEnum.TOTAL_SUM_CLOSED_INVOICE,
      ];
    }
    return [];
  }
}
