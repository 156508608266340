<troi-modal-header>
  <span
    >{{ 'Timerecording.ProjectTime' | translate }} > {{ workLogDate | date : 'dd.MM.yyyy' }}</span
  >
</troi-modal-header>
<troi-modal-content style="overflow-y: inherit">
  <div>
    <tr-log-projecttime [LogDate]="workLogDate"></tr-log-projecttime>
  </div>
</troi-modal-content>
<troi-modal-footer> </troi-modal-footer>
