<span
  [style.color]="color"
  [style.font-size.px]="13"
  class="toggle-add-dialog"
  [ngbPopover]="popContent"
  [popoverTitle]="popTitle"
  triggers="manual"
  #recordProjectTime="ngbPopover"
  popoverClass="troi-popover record-project-time"
  (click)="toggleOverlay(recordProjectTime)"
  [placement]="placement"
  autoClose="outside"
  >{{ billingQuantity }}</span
>

<ng-template #popTitle>
  <h3 class="popover-title">{{ 'Timerecording.Tracked_Hours' | translate }}</h3>
</ng-template>
<ng-template #popContent>
  <div class="align-items-center" style="position: relative">
    <troi-loading-spinner *ngIf="loading"></troi-loading-spinner>
  </div>
  <div class="projectHead">
    <div class="row">
      <div class="col-10">
        <div>
          <span class="contentHead">{{ projectItem.cp.cpPathHeader }}</span
          ><br />
          <span class="contentDesc" [innerHTML]="projectItem.cp.cpPathTail"></span>
          <br />
          <span class="contentId">K-{{ projectItem.cp.cpId }}</span>
        </div>
      </div>
      <div class="col-2 d-flex justify-content-center" *ngIf="0 < currentPageData.length && currentPage > 0">
        <div
          class="actions__icon mx-2"
          style="cursor: pointer; display: flex; justify-content: center; align-items: center"
          (click)="showReplaceProjectFunctionality()"
        >
          <svg
            fill="#000000"
            width="18px"
            height="22px"
            viewBox="0 0 512 512"
            data-name="Layer 1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M64,256H34A222,222,0,0,1,430,118.15V85h30V190H355V160h67.27A192.21,192.21,0,0,0,256,64C150.13,64,64,150.13,64,256Zm384,0c0,105.87-86.13,192-192,192A192.21,192.21,0,0,1,89.73,352H157V322H52V427H82V393.85A222,222,0,0,0,478,256Z"
            />
          </svg>
        </div>
      </div>
    </div>
  </div>
  <div
    style="display: flex"
    class="projectFind"
    *ngIf="0 < currentPageData.length && showReplaceProject && currentPage > 0"
  >
    <div style="width: 90%">
      <troi-popup-search-project
        (searchStringChanged)="onProjectSearchStringChanged($event)"
        [searchResult]="serchProjectData"
        placeholder="{{ 'Desktop.Widgets.Projecttimes.SearchCalcPosition.Project' | translate }}"
        labelFieldName1="name"
        valueFieldName="id"
        [loading]="!loadedProjects"
        icon="icon-folder"
        (valueSelected)="onProjectSelected($event)"
      >
      </troi-popup-search-project>
    </div>
    <div style="width: 10%; display: flex; justify-content: center; align-items: center">
      <troi-icon class="closeIcon" icon="icon-close" (click)="hideReplaceProjectFunctionality()"></troi-icon>
    </div>
  </div>

  <form [formGroup]="createForm" (ngSubmit)="onSubmit(recordProjectTime)" class="popover-content">
    <div>
      <ng-container
        *ngIf="
          (!isEnableTimerecordingAdditionalFields && !isRestExpenseEstimationActive) ||
          (isEnableTimerecordingAdditionalFields && isRestExpenseEstimationActive)
        "
      >
        <div class="row">
          <div class="col-6">
            <ng-container *ngTemplateOutlet="datepicker"></ng-container>
          </div>
          <div class="col-6">
            <ng-container *ngTemplateOutlet="hoursInput"></ng-container>
          </div>
        </div>
        <ng-container *ngIf="isEnableTimerecordingAdditionalFields && isRestExpenseEstimationActive">
          <div class="row">
            <div class="col-6">
              <ng-container *ngTemplateOutlet="actualHoursInput"></ng-container>
            </div>
            <div class="col-6">
              <ng-container *ngTemplateOutlet="estimationInput"></ng-container>
            </div>
          </div>
        </ng-container>
      </ng-container>

      <ng-container
        *ngIf="
          (isEnableTimerecordingAdditionalFields || isRestExpenseEstimationActive) &&
          !(isEnableTimerecordingAdditionalFields && isRestExpenseEstimationActive)
        "
      >
        <div class="row">
          <div class="col-6">
            <ng-container *ngTemplateOutlet="datepicker"></ng-container>
          </div>
          <div class="col-3">
            <ng-container *ngTemplateOutlet="hoursInput"></ng-container>
          </div>
          <div class="col-3">
            <ng-container *ngIf="isEnableTimerecordingAdditionalFields">
              <ng-container *ngTemplateOutlet="actualHoursInput"></ng-container>
            </ng-container>
            <ng-container *ngIf="isRestExpenseEstimationActive">
              <ng-container *ngTemplateOutlet="estimationInput"></ng-container>
            </ng-container>
          </div>
        </div>
      </ng-container>

      <ng-template #datepicker>
        <span>{{ 'Desktop.Widgets.Projecttimes.Date' | translate }}</span>
        <tnd-datepicker
          [ngClass]="{ 'tnd-text-input--error': isErrorField('date') }"
          [fieldInvalid]="false"
          [validationEnabled]="true"
          [rangeFrom]="fromDate"
          [rangeTo]="toDate"
          [backwardDays]="backwardDays"
          [singleDatePicker]="true"
          [emitIfEmpty]="true"
          [disabled]="false"
          (rangeChanged)="dateChanged($event)"
          [requiredFieldErrorMessage]="'Booking.error.receiptDateIsRequired'"
          [hideRanges]="true"
          ngClass="tnd-create-event-datepicker"
          [minDate]="minDate"
          [maxDate]="maxDate"
        >
        </tnd-datepicker>
      </ng-template>
      <ng-template #hoursInput>
        <span>{{ ('Desktop.Widgets.Projecttimes.ProjectTime' | translate) + ' *' }}</span>
        <div class="d-flex">
          <troi-icon [icon]="'icon-clock-open'"></troi-icon>
          <troi-time-input
            class="ms-2"
            formControlName="workTime"
            id="time-picker"
            #timePicker
            [placeholder]="'00:00'"
            [comment]="createForm.get('externalComment').value"
            (commentChange)="createForm.get('externalComment').setValue($event)"
          ></troi-time-input>
        </div>
      </ng-template>

      <ng-template #actualHoursInput>
        <span>{{ 'Timerecording.Actual_Hours' | translate }}</span>
        <div class="d-flex">
          <troi-icon [icon]="'icon-clock-open'"></troi-icon>
          <troi-time-input class="ms-2" formControlName="actualHours" [placeholder]="'00:00'"></troi-time-input>
        </div>
      </ng-template>

      <ng-template #estimationInput>
        <span>{{ 'Timerecording.Rest_Estimation' | translate }}</span>
        <div class="d-flex">
          <troi-icon [icon]="'icon-clock-open'"></troi-icon>
          <troi-time-input class="ms-2" formControlName="estimation" [placeholder]="'00:00'"></troi-time-input>
        </div>
      </ng-template>
    </div>
    <div *ngIf="isErrorField('workTime')" class="tnd-error-msg">
      {{ getErrorForField('workTime') | translate }}
    </div>
    <div>
      <div class="tnd-text-icon-input" *ngIf="timeService.isBillingInternalCommentVisibility()">
        <span class="input__wrapper">
          <troi-textfield
            formControlName="internalComment"
            [icon]="'icon-empty-bubble'"
            [multiline]="true"
            [placeholder]="
              isTimeRecordingInternalCommentRequired
                ? ('Timerecording.Internal_Comment_Required' | translate)
                : ('Timerecording.Internal_Comment' | translate)
            "
          ></troi-textfield>
          <troi-dropdown-list
            #dropdownList
            [popoverContainer]="''"
            [options]="boilerPlates.internalComment"
            [ngbTooltip]="'Timerecording.boiler_plates' | translate"
            [placement]="'top'"
            (selectedEmitter)="createForm.controls['internalComment'].setValue($event.value)"
            *ngIf="isEnabledBoilerPlatesInComments && boilerPlates.internalComment.length > 0"
          >
            <troi-icon-button icon="icon-arrow-down-list" skipStopPropagation></troi-icon-button>
          </troi-dropdown-list>
        </span>

        <div class="error-message" *ngIf="InternalCommentErrorShow">
          {{ errorMessages.internalComment }}
        </div>
      </div>
      <div class="tnd-text-icon-input">
        <span class="input__wrapper">
          <troi-textfield
            formControlName="externalComment"
            [icon]="'icon-empty-bubble'"
            [multiline]="true"
            [placeholder]="
              isTimeRecordingExternalCommentRequired
                ? ('Timerecording.External_Comment_Required' | translate)
                : ('Timerecording.External_Comment' | translate)
            "
          ></troi-textfield>
          <troi-dropdown-list
            #dropdownList
            [popoverContainer]="''"
            [options]="boilerPlates.externalComment"
            [ngbTooltip]="'Timerecording.boiler_plates' | translate"
            [placement]="'top'"
            (selectedEmitter)="createForm.controls['externalComment'].setValue($event.value)"
            *ngIf="isEnabledBoilerPlatesInComments && boilerPlates.externalComment.length > 0"
          >
            <troi-icon-button icon="icon-arrow-down-list" skipStopPropagation></troi-icon-button>
          </troi-dropdown-list>
        </span>

        <div class="error-message" *ngIf="ExternalCommentErrorShow">
          {{ errorMessages.externalComment }}
        </div>
      </div>
      <div class="d-flex mt-2">
        <div class="flex-shrink-1 mr-2">
          <input
            type="checkbox"
            [ngModel]="!this.isBillable"
            (ngModelChange)="updateIsBillable(!$event)"
            [ngModelOptions]="{ standalone: true }"
            [disabled]="disableBillable"
          />
        </div>
        <div class="mx-2">{{ 'Timerecording.NB' | translate }}</div>
      </div>
      <div class="mt-2">
        <div class="d-flex justify-content-between">
          <div *ngIf="0 < currentPageData.length">
            <div class="d-flex mt-2" (click)="addnewtrackedhours()">
              <troi-icon
                style="cursor: pointer"
                icon="icon-plus"
                large="true"
                class="actions__icon actions__icon--large"
              ></troi-icon>
              <span class="mx-2" style="cursor: pointer">{{ 'Timerecording.Tracked_More_Hours' | translate }}</span>
            </div>
            <div class="d-flex mt-3" (click)="deleteLog(currentPage)">
              <troi-icon
                style="cursor: pointer"
                icon="icon-trash-can"
                large="true"
                class="actions__icon actions__icon--large"
              ></troi-icon>
              <span class="mx-2" style="cursor: pointer">{{ 'Timerecording.hours_remover' | translate }}</span>
            </div>
            <div class="d-flex mt-3">
              <div class="projectApproval" *ngIf="this.currentPageData[0].billingIsApprovalRequired && currentPage > 0">
                <!-- pending -->
                <div class="d-flex flex-row" *ngIf="this.currentPageData[0].billingApprovalStatus === 1">
                  <div><troi-icon icon="icon-circle-not-closed"></troi-icon></div>
                  <div>
                    <span class="mx-2">{{ 'Timerecording.Pending' | translate }}</span>
                  </div>
                </div>
                <!-- approve -->
                <div class="d-flex flex-row" *ngIf="this.currentPageData[0].billingApprovalStatus === 2">
                  <div>
                    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="23" height="23" viewBox="0 0 24 24">
                      <path
                        d="M 20.292969 5.2929688 L 9 16.585938 L 4.7070312 12.292969 L 3.2929688 13.707031 L 9 19.414062 L 21.707031 6.7070312 L 20.292969 5.2929688 z"
                      ></path>
                    </svg>
                  </div>
                  <div>
                    <span class="mx-2">{{ 'Timerecording.Approved' | translate }}</span>
                  </div>
                </div>
                <!-- reject -->
                <div class="d-flex flex-row" *ngIf="this.currentPageData[0].billingApprovalStatus === 3">
                  <div>
                    <troi-icon icon="icon-close" class="icon-close"></troi-icon>
                  </div>
                  <div>
                    <span class="mx-2">{{ 'Timerecording.Declined' | translate }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div class="pagination mt-2" *ngIf="0 < currentPageData.length">
              <button
                type="button"
                (click)="changePage(currentPage - 1)"
                [disabled]="currentPage === 1 || currentPage === 0"
              >
                ←
              </button>
              <ng-container *ngFor="let page of getPaginationArray(); let last = last">
                <p class="d-flex" style="margin-top: 18px" *ngIf="last">
                  {{ currentPage }}/{{ getPaginationArray().length }}
                </p>
              </ng-container>
              <button
                type="button"
                (click)="changePage(currentPage + 1)"
                [disabled]="currentPage === getPaginationArray().length"
              >
                →
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-3" *ngIf="backwardDays !== 0">
        <span>{{ 'Timerecording.spread.title' | translate }}</span>
        <div class="d-flex" style="align-items: center">
          <tnd-datepicker
            [rangeFrom]="spreadFromDate"
            [rangeTo]="spreadToDate"
            (rangeChanged)="spreadDateChanged($event)"
            [placeholder]="'Tasks.labels.dateFormat' | translate"
            [emitIfEmpty]="false"
            [minDate]="minDate"
            [maxDate]="maxDate"
            [ngbTooltip]="'Timerecording.spread.tooltip' | translate"
            [placement]="'top'"
          ></tnd-datepicker>
          <span class="ms-4">
            <button
              class="btn btn-secondary no-text-wrap"
              [disabled]="saving || !spreadRangeValid"
              (click)="saveAndSpread(recordProjectTime)"
            >
              {{ 'Timerecording.spread.button' | translate }}
            </button>
          </span>
        </div>
        <div *ngIf="!spreadRangeValid" class="tnd-error-msg">
          {{ 'Timerecording.spread.error.range_not_valid' | translate }}
        </div>
      </div>
    </div>

    <div class="d-flex mt-5">
      <button
        type="button"
        class="btn btn-secondary button-cancel"
        (click)="closeOverlay(recordProjectTime)"
        [disabled]="saving"
      >
        {{ 'Desktop.Widgets.Projecttimes.Cancel' | translate }}
      </button>
      <button class="btn bin-primary button-log" [disabled]="saving">
        {{ 'Desktop.Widgets.Projecttimes.Record' | translate }}
      </button>
    </div>
  </form>
</ng-template>
