import { HttpClient } from '@angular/common/http';
import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { StorageNotificationService } from '../../../../../app/core/notifications/storageNotification.service';
import { StorageKeys } from '../../../../core/storage/storage.keys';
import { StorageService } from '../../../../core/storage/storage.service';
import { WorkTimeSummaryInterface } from '../../interfaces/work-time-summary-response.interface';
import { TimeRecordingService } from '../../time-recording.service';

@Component({
  selector: 'tr-log-workingtime',
  templateUrl: './tr-log-workingtime.component.html',
  styleUrls: ['./tr-log-workingtime.component.scss'],
})
export class TrLogWorkingtimeComponent implements OnInit, OnChanges, OnDestroy {
  summaryData: WorkTimeSummaryInterface;
  private reloadingSubscription: Subscription;
  @Input() LogDate: string;
  @Input() showAbsence = false;
  url: any;
  LogData: any[] = [];
  tableData: any[] = [];
  totalWorkingTime: number;
  showError = false;
  showErrorMessage: string;
  userId: number;
  @Input() workLogUserId?: number;
  public loading = false;
  public isEditable = false;
  public showDeleteIcon = true;

  constructor(
    private storageService: StorageService,
    private http: HttpClient,
    public timeService: TimeRecordingService,
    private translate: TranslateService,
    private notificationService: StorageNotificationService,
  ) {
    this.timeService.clearRedBordersEvent.subscribe(() => {
      this.clearRedBorders();
    });

    this.timeService.highlightUnsavedItemsEvent.subscribe(() => {
      this.highlightUnsavedItems();
    });

    /* reloading after add new */
    this.reloadingSubscription = this.timeService.reloading.subscribe((value) => {
      setTimeout(() => {
        this.getData();
      }, 1500);
    });
  }

  ngOnInit(): void {
    if (this.workLogUserId) {
      this.userId = this.workLogUserId;
    } else {
      const user = this.storageService.getItem(StorageKeys.USER);
      if (user) {
        this.userId = user.id;
      }
    }

    this.getData();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.LogDate) {
      this.showError = false;
      this.showErrorMessage = '';
      this.getData();
    }
  }

  ngOnDestroy() {
    this.userId = null;
    this.LogDate = null;
  }

  getData() {
    if (!this.userId || this.loading) {
      return;
    }

    this.isEditable = this.timeService.checkIsEditable(this.LogDate, 'W');

    /* Get Data */
    this.url = this.timeService.getWorkingTimeLogData(this.userId, this.LogDate);
    if (this.url) {
      this.loading = true;
      this.url.subscribe((response: any) => {
        this.loading = false;
        this.LogData = response;
        this.setDataTable();
        this.getDayData();
      });
    }
  }

  setDataTable() {
    const formattedData = [];

    let total = 0;
    this.LogData.forEach((item, index) => {
      total += item.workTimeQuantity;
      const checkInObj = {
        name: 'Check In',
        checkIn: this.timeService.convertToTime(item.checkIn),
        checkOut: this.timeService.convertToTime(item.checkOut),
        showCheckInInput: false,
        showCheckOutInput: false,
        total: item.checkQuantity,
      };

      formattedData.push(checkInObj);

      item.breaks.forEach((breakItem, breakIndex) => {
        const breakObj = {
          name: 'Break',
          checkIn: this.timeService.convertToTime(breakItem.breakStart),
          checkOut: this.timeService.convertToTime(breakItem.breakStop),
          showCheckInInput: false,
          showCheckOutInput: false,
          total: breakItem.breakTimeQuantity,
        };
        formattedData.push(breakObj);
      });
    });

    this.tableData = formattedData;
    if (this.tableData.length === 0) {
      this.showDeleteIcon = false;
      this.addData('Check In');
      this.addData('Break', 1);
    } else {
      this.showDeleteIcon = true;
      if (!this.checkLastBreakRow()) {
        this.addData('Break', this.tableData.length);
      }
    }
    this.totalWorkingTime = total;
  }

  addData(name = '', index = this.tableData.length, byButtonClick = false) {
    this.showError = false;
    this.showErrorMessage = '';
    if (byButtonClick === true) {
      let errorExists = false;

      this.tableData.forEach((item) => {
        if (item.checkIn === '' && item.checkOut === '' && name === item.name) {
          this.showError = true;
          errorExists = true;

          if (name === 'Check In') {
            this.notificationService.showError(
              this.translate.instant('Timerecording.Check_In_blank_field_already_exists'),
            );
          } else if (name === 'Break') {
            this.notificationService.showError(
              this.translate.instant('Timerecording.Break_blank_field_already_exists'),
            );
          }
        }
      });

      if (errorExists) {
        return;
      }
    }

    if (name === 'Break') {
      let nextIndex = index;
      while (nextIndex < this.tableData.length && this.tableData[nextIndex].name === 'Break') {
        nextIndex++;
      }
      index = nextIndex;
    }

    const newData = {
      name,
      checkIn: '',
      checkOut: '',
      showCheckInInput: false,
      showCheckOutInput: false,
      total: 0,
    };

    this.tableData.splice(index, 0, newData);
  }

  showInputField(dataItem: any, field: string) {
    if (!this.isEditable) {
      return false;
    }

    // this.tableData.forEach((dataItemData) => {
    //   dataItemData.showCheckInInput = false;
    //   dataItemData.showCheckOutInput = false;
    // });
    // dataItem['showCheckInInput'] = true;
    // dataItem['showCheckOutInput'] = true;
    this.tableData.forEach((dataItemData) => {
      dataItemData.showCheckInInput = true;
      dataItemData.showCheckOutInput = true;
    });
    dataItem['showCheckInInput'] = true;
    dataItem['showCheckOutInput'] = true;
  }

  public onEnterUp(event, item, field): void {
    event.preventDefault();
    if (field === 'checkIn') {
      this.onModelChange(item.checkIn, item, field);
    } else if (field === 'checkOut') {
      this.onModelChange(item.checkOut, item, field);
    }
  }

  onModelChange(newValue, item, field) {
    if (newValue) {
      this.getValueInputField(item, field);
    }
  }

  getValueInputField(item: any, field: string) {
    if (item.name === 'Break') {
      if (item.checkIn === '00:00' || item.checkIn === null || item.checkIn === '') {
        this.notificationService.showWarning(this.translate.instant('Timerecording.break_start_warning'));
        return;
      }
      if (item.checkOut === '00:00' || item.checkOut === null || item.checkOut === '') {
        this.notificationService.showWarning(this.translate.instant('Timerecording.break_end_warning'));
        return;
      }
    }

    this.timeService.setUnsavedChanges(true);
    this.timeService.popupsetUnsavedChanges(true);
    this.showError = false;
    this.setDataForUpdate();
    this.timeService.removeUnsavedItem(item);
    this.showErrorMessage = '';
  }

  hideInputField(item: any, field: string) {
    this.showError = false;
    item['showCheckInInput'] = true;
    item['showCheckOutInput'] = true;
  }

  onInputValueChanged(item: any, Index: number) {
    item.indexId = Index;
    this.timeService.setUnsavedChanges(true);
    this.timeService.addUnsavedItem(item);
  }

  setDataForUpdate(action = 'SAVE') {
    this.clearRedBorders();

    this.timeService.setUnsavedChanges(false);

    const newJson = [];

    for (const item of this.tableData) {
      const checkInDateTime = item.checkIn === '' ? null : `${this.LogDate}T${item.checkIn}:00`;
      const checkOutDateTime = item.checkOut === '' ? null : `${this.LogDate}T${item.checkOut}:00`;

      if (item.name === 'Break') {
        if (item.checkIn === '00:00' || item.checkOut === '00:00') {
          continue;
        }

        if (
          item.checkIn === undefined ||
          item.checkIn === null ||
          item.checkIn === '' ||
          item.checkOut === undefined ||
          item.checkOut === null ||
          item.checkOut === ''
        ) {
          continue;
        }

        if (newJson.length > 0) {
          const lastItem = newJson[newJson.length - 1];
          if (!lastItem.breaks) {
            lastItem.breaks = [];
          }
          lastItem.breaks.push({
            breakStart: checkInDateTime,
            breakStop: checkOutDateTime,
          });
        }
      } else {
        newJson.push({
          checkIn: checkInDateTime,
          checkOut: checkOutDateTime,
          breaks: [],
        });
      }
    }

    this.url = this.timeService.createWorkingTimeLogData(this.userId, this.LogDate, newJson);
    this.loading = true;
    if (this.url) {
      this.url.subscribe(
        (response: any) => {
          if (action === 'SAVE') {
            this.notificationService.showSuccess(this.translate.instant('Common.labels.dataSuccessfullySaved'));
          } else if (action === 'DELETE') {
            this.notificationService.showSuccess(this.translate.instant('Common.labels.dataSuccessfullyDeleted'));
          }
          this.loading = false;
          this.getData();
          this.showError = false;
          this.showErrorMessage = '';
          this.timeService.reloading.next(true);
        },
        (error: any) => {
          this.loading = false;
          this.showError = true;
          if (error.error) {
            if (error.error.breaks && error.error.breaks.length > 0) {
              // this.showErrorMessage = error.error.breaks[0].breakStop[0];
              this.notificationService.showError(error.error.breaks[0].breakStop[0]);
            } else if (error.error.checkOut && error.error.checkOut.length > 0) {
              // this.showErrorMessage = error.error.checkOut[0];
              this.notificationService.showError(error.error.checkOut[0]);
            } else if (error.error.checkIn && error.error.checkIn.length > 0) {
              // this.showErrorMessage = error.error.checkIn[0];
              this.notificationService.showError(error.error.checkIn[0]);
            } else {
              this.notificationService.showError(this.translate.instant('Common.error.general'));
              // this.showErrorMessage = 'Something went wrong. Please try again.';
            }
          } else {
            // this.showErrorMessage = 'Something went wrong. Please try again.';
          }
        },
      );
    }
  }

  deleteData(indexNo: number, item: any) {
    if (item.checkIn === '' && item.checkOut === '') {
      this.tableData.splice(indexNo, 1);
      this.notificationService.showSuccess(this.translate.instant('Common.labels.dataSuccessfullyDeleted'));
      return;
    }

    if (indexNo >= 0 && indexNo < this.tableData.length) {
      this.tableData.splice(indexNo, 1);
      if (this.tableData.length !== 0) {
        this.notificationService.showSuccess(this.translate.instant('Common.labels.dataSuccessfullyDeleted'));
        this.setDataForUpdate('DELETE');
      }
    }

    if (this.tableData.length === 0) {
      this.url = this.timeService.deleteWorkingTimeLogData(this.userId, this.LogDate);
      if (this.url) {
        this.url.subscribe(
          (response: any) => {
            this.notificationService.showSuccess(this.translate.instant('Common.labels.dataSuccessfullyDeleted'));

            this.getData();
            this.showError = false;
            this.showErrorMessage = '';
            this.timeService.reloading.next(true);
          },
          (error: any) => {
            this.showError = true;
            this.notificationService.showError(this.translate.instant('Common.error.general'));
          },
        );
      }
    }
  }

  showAddButton() {
    if (!this.isEditable) {
      return false;
    }
    let lastIndex = -1;

    this.tableData.forEach((item, i) => {
      lastIndex = i;
    });

    if (lastIndex !== -1) {
      const lastItem = this.tableData[lastIndex];
      if (lastItem.checkIn !== '00:00' && lastItem.checkOut !== '00:00' && !this.showError) {
        return true;
      }
    }
    return false;
  }

  highlightUnsavedItems() {
    const unsavedItems = this.timeService.getUnsavedItems();
    unsavedItems.forEach((item: any) => {
      const inputElements = Array.from(document.getElementsByClassName(`input-${item.indexId}`));
      for (const inputElement of inputElements) {
        inputElement.setAttribute('style', 'border-bottom: 2px solid red;');
      }
    });
  }

  clearRedBorders() {
    const unsavedItems = this.timeService.getUnsavedItems();
    unsavedItems.forEach((item: any) => {
      const inputElements = document.getElementsByClassName(`input-${item.indexId}`);
      const inputArray = Array.from(inputElements);
      for (const inputElement of inputArray) {
        const currentStyles = inputElement.getAttribute('style');
        if (currentStyles) {
          const updatedStyles = currentStyles.replace('border-bottom: 2px solid red;', '');
          inputElement.setAttribute('style', updatedStyles);
        }
      }
    });
  }

  checkLastBreakRow() {
    if (this.tableData.length > 0) {
      const lastItem = this.tableData[this.tableData.length - 1];
      if (lastItem && lastItem.hasOwnProperty('name') && lastItem.name === 'Break') {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  saveData() {
    this.timeService.setUnsavedChanges(true);
    this.timeService.popupsetUnsavedChanges(true);
    this.setDataForUpdate();
  }

  getDayData() {
    this.url = this.timeService.getDayData(this.LogDate, this.userId);
    if (this.url) {
      this.loading = true;
      this.url.subscribe((response: any) => {
        this.loading = false;
        if (response.length > 0) {
          /* summary */
          this.summaryData = response[0].workingTimeSummary;
        }
      });
    }
  }
}
